import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { API_URL, API_URL_Upload, Rresource_Path } from "../constants";
import { useAuth } from "react-oidc-context";
import { CityApi, CityDto, Configuration, ConfigurationParameters, CountryApi, LocationApi, LocationDto, PartyApi, PartyUserApi, ProfileApi, RegisterDto, SubdivisionApi } from "../api/najd";
import { AutoComplete, Button, Card, Cascader, CascaderProps, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Result, Row, Select, SelectProps, Spin, Upload, message } from "antd";
import { RuleObject } from "antd/es/form";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { OtpConfirm } from "../components/OtpConfirm";
import { useLocation, useNavigate } from "react-router-dom";

interface SetPasswordProps {
}

export const SetPassword: React.FC<SetPasswordProps> = ({ }) => {
  const { i18n, t } = useTranslation();
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
  };

  const configuration = new Configuration(configParameters);
  const partyUserApi = new PartyUserApi(configuration);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const username = (location.state as { username: string })?.username;

  const onFinish = async (values: { otp: string; newPassword: string; confirmPassword: string }) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('Passwords do not match');
      return;
    }

    setIsLoading(true);
    try {
      // API call to reset password
      await partyUserApi.partyUserSetNewPassword({
        username: username,
        otp: values.otp,
        newPassword: values.newPassword,
      });

      // Show success message and navigate back to login page
      message.success('Password has been reset!');
      //navigate('/login');
      Backtosite();

    } catch (error: any) {
      message.error('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const Backtosite = () => {
    window.location.href = 'https://wecare360.org/';
    return null;
  };
  return (
    <>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col>

          {/* <Spin spinning={loading}> */}
          <Card>
            <img src="/FullLogo.png" width={350}></img>
            <br />
            <br />
            <br />
              <Radio.Group defaultValue={i18n.language} buttonStyle="solid" >
                <Radio.Button value="en" onClick={()=>{  i18n.changeLanguage("en");}}>English</Radio.Button>
                <Radio.Button value="ar" onClick={()=>{  i18n.changeLanguage("ar");}}>العربية</Radio.Button>
              </Radio.Group>
            <div style={{ maxWidth: 400, margin: 'auto', padding: '1rem' }}>
              <h2>{t('Set New Password')}</h2>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label={t("OTP Code")}
                  name="otp"
                  rules={[{ required: true, message: t('Please enter the OTP code')??'' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("New Password")}
                  name="newPassword"
                  rules={[{ required: true, message: t('Please enter your new password')??'' }]}
                >
                  <Input.Password  />
                </Form.Item>
                <Form.Item
                  label={t("Repeat New Password")}
                  name="confirmPassword"
                  rules={[{ required: true, message: t('Please confirm your new password')??'' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading} block>
                    {isLoading ? 'Setting Password...' : t('Set Password')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Button htmlType="button" onClick={Backtosite}>
              {t('Back to login')}
            </Button>
          </Card>
          {/* </Spin > */}
        </Col>
      </Row></>
  );
};