import { LoginForm, ProFormCaptcha, ProFormText } from '@ant-design/pro-components';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { API_URL } from '../constants';
import { Form, Modal, message } from 'antd';
import { Configuration, ConfigurationParameters, PartyApi, PartyDto, ProfileApi } from '../api/najd';
import { LockOutlined, MobileOutlined } from '@ant-design/icons';


interface OtpConfirmProps {
  phone: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const OtpConfirm: React.FC<OtpConfirmProps> = ({ phone, open, onConfirm, onCancel }) => {
  const auth = useAuth();
  const formData : any = {
    phoneNumber : phone,
    captcha:""
  }
  const [form] = Form.useForm(); // Create a form instance using the useForm hook
  const { t } = useTranslation();

  const token = auth.user?.access_token;

  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
    accessToken: token,
    baseOptions: {
      headers: { Authorization: 'Bearer ' + token },
    }
  };
  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(configuration);

  const [secrectCode, setSecrectCode] = useState<string>("0000");
  const generateRandomNumber = (): number => {
    return Math.floor(Math.random() * 9000) + 1000;
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);

  const iconStyles: CSSProperties = {
    marginInlineStart: '16px',
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: '24px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  };

  const handleSubmit = async (values: any) => {
    console.log('Form values:', values);
    if (values.captcha === secrectCode) {
      message.success(t('Activation Successfully'));
      onConfirm();
      // Perform your submit logic here
      // Return a Promise that resolves to a boolean or void
      return true; // Replace with your own logic
    } else {
      message.error(t('Not Correct Code!'));
      return false; // Replace with your own logic
    }
  };
  return (
    <Modal
      open={open}
      title={t("Activate Patient By Sms")}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={[

      ]}
      // okText={t("Submit")}
      // cancelText={t("Cancel")}
      onCancel={onCancel}
    // onOk={() => {

    //   onCreate();
    // }}
    >
      <LoginForm
        submitter={{
          searchConfig: {
            resetText: 'reset',
            submitText: t('Submit'),
          },
        }}
        form={form}
        // logo={<img src="/FullLogo.png" className="login-logo" alt="Logo" />}
        subTitle={t('AppName')}
        onFinish={handleSubmit}
      >
        <>
          <ProFormText
            fieldProps={{
              size: 'large',
              prefix: <MobileOutlined className={'prefixIcon'} />,
            }}
            name="phoneNumber"
            placeholder={'mobile'}
            disabled={true}
            rules={[
              {
                required: true,
                message: t('Required field') ?? '',
              },
              // {
              //   pattern: /^1\d{10}$/,
              //   message: t('Phone number not correct!')??'',
              // },
            ]}
          />
          <ProFormCaptcha
            fieldProps={{
              size: 'large',
              prefix: <LockOutlined className={'prefixIcon'} />,
            }}
            captchaProps={{
              size: 'large',
            }}
            placeholder={t('Please enter verification code!') ?? ''}
            captchaTextRender={(timing, count) => {
              if (timing) {
                if (count == 1) {
                  console.log('xxxx');
                  setSecrectCode('xxxx');
                }
                return `${count} ${t('get verification code') ?? ''}`;
              }
              return t('get verification code') ?? '';
            }}
            name="captcha"
            rules={[
              {
                required: true,
                message: t('Please enter verification code!') ?? '',
              },
            ]}
            onGetCaptcha={async () => {
              const randomFourDigitNumber: number = generateRandomNumber();
              console.log(randomFourDigitNumber.toString());

              partyApi.partySetndVerficationCode({ phone: formData.phoneNumber, text: `كود الدخول : ${randomFourDigitNumber}` })
                .then(response => {
                  // Handle the API response
                  message.success(t('Get the verification code successfully!'));
                  setSecrectCode(randomFourDigitNumber.toString());
                })
                .catch(error => {
                  // Handle the API error
                  console.error(error);
                  message.error(error);
                });
            }}
          />
        </>

        <div
          style={{
            marginBlockEnd: 24,
          }}
        >
        </div>
      </LoginForm>
    </Modal>
  );
};