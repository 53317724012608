import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { API_URL, API_URL_Upload, Rresource_Path } from "../constants";
import { useAuth } from "react-oidc-context";
import { CityApi, CityDto, Configuration, ConfigurationParameters, CountryApi, LocationApi, LocationDto, PartyApi, PartyUserApi, ProfileApi, RegisterDto, SubdivisionApi } from "../api/najd";
import { AutoComplete, Button, Card, Cascader, CascaderProps, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Result, Row, Select, SelectProps, Spin, Upload, message } from "antd";
import { RuleObject } from "antd/es/form";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { OtpConfirm } from "../components/OtpConfirm";
import { useNavigate } from "react-router-dom";

interface ForgetPasswordProps {
}

export const ForgetPassword: React.FC<ForgetPasswordProps> = ({ }) => {
  const { i18n, t } = useTranslation();
  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
  };

  const configuration = new Configuration(configParameters);
  const partyUserApi = new PartyUserApi(configuration);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: { username: string }) => {
    setIsLoading(true);
    try {
      // API call to request OTP
      await partyUserApi.partyUserForgetPassword({
        username: values.username
      });

      // Show success message and navigate to SetPasswordPage
      message.success('OTP has been sent to your email!');
      navigate('/set-password', { state: { username: values.username } });
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        message.error(error.response.data.error.message || 'User not found with this username');
      } else {
        message.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const Backtosite = () => {
    window.location.href = 'https://wecare360.org/';
    return null;
  };
  return (
    <>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col>

          {/* <Spin spinning={loading}> */}
          <Card>
            <img src="/FullLogo.png" width={350}></img>
            <br />
            <br />
            <br />
              <Radio.Group defaultValue={i18n.language} buttonStyle="solid" >
                <Radio.Button value="en" onClick={()=>{  i18n.changeLanguage("en");}}>English</Radio.Button>
                <Radio.Button value="ar" onClick={()=>{  i18n.changeLanguage("ar");}}>العربية</Radio.Button>
              </Radio.Group>
            <div style={{ maxWidth: 400, margin: 'auto', padding: '1rem' }}>
            <h2>{t('Forgot Password')}</h2>
              <div>{t('forget_password_msg')}</div>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label={t("Username")}
                  name="username"
                  rules={[{ required: true, message: t('Please enter your username')??'' }]}
                >
                  <Input placeholder={t("Enter your username")??''} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading} block>
                    {isLoading ? 'Submitting...' : t('Send OTP')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Button htmlType="button" onClick={Backtosite}>
              {t('Back to login')}
            </Button>
          </Card>
          {/* </Spin > */}
        </Col>
      </Row></>
  );
};