import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { API_URL, API_URL_Upload, Rresource_Path } from "../constants";
import { useAuth } from "react-oidc-context";
import { CityApi, CityDto, Configuration, ConfigurationParameters, CountryApi, LocationApi, LocationDto, PartyApi, ProfileApi, RegisterDto, SubdivisionApi } from "../api/najd";
import { AutoComplete, Button, Card, Cascader, CascaderProps, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Result, Row, Select, SelectProps, Spin, Upload, message } from "antd";
import { RuleObject } from "antd/es/form";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { OtpConfirm } from "../components/OtpConfirm";


const { Option } = Select;

interface DataNodeType {
  value: string;
  label: string;
  children?: DataNodeType[];
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

interface FormRegisterProps {
}

export const FormRegister: React.FC<FormRegisterProps> = ({ }) => {
  const [formData, setUserData] = useState<RegisterDto>();
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using the useForm hook
  const [countryOptions, setCountryOptions] = useState<SelectProps['options']>([]);
  const [showConfrimOtp, setshowConfrimOtp] = useState<boolean>(false);
  const [isresultModalOpen, setisresultModalOpen] = useState<boolean>(false);
  const [phonenumber, setphonenumber] = useState<string>("");

  const configParameters: ConfigurationParameters = {
    basePath: API_URL,
  };

  const configuration = new Configuration(configParameters);
  const partyApi = new PartyApi(configuration);
  const countryApi = new CountryApi(configuration);

  useEffect(() => {
    fetchCountries()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ fullName, id }) => (
          {
            label: fullName,
            value: id
          }
        ));
        setCountryOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
  }, []); // Add refreshTrigger as a dependency


  const fetchCountries = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return countryApi.countryGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formData); // Set initial form values when rowData changes
  }, [form, formData]);
  // Function to handle form submission
  const handleSubmit = () => {
    form.validateFields().then(values => {
      setphonenumber(values.phoneNumber);
      setshowConfrimOtp(true);
    });
  };

  function normlizeFileVariable(value: null | string | any): string | null {
    if (value === null) {
      return null;
    } else if (typeof value === "string") {
      // Variable is a string
      return value;
    } else if (typeof value === "object") {
      // Variable is an object
      return value.name;
    } else {
      return value;
    }
  }
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }}>
        <Option value="966">+966</Option>
      </Select>
    </Form.Item>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const Backtosite = () => {
    window.location.href = 'https://wecare360.org/';
    return null;
  };

  const validatePhoneNumber = async (_: RuleObject, value: string) => {
    try {
      const response = await partyApi.partyIsValidPhoneToUse(value);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already Taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };
  const validateIdentifier = async (_: RuleObject, value: string) => {
    try {
      const response = await partyApi.partyIsValidIdentifierToUse(value);
      const isValid = response.data;
      if (isValid) {
        return Promise.resolve();
      } else {
        return Promise.reject(t('Already taken'));
      }
    } catch (error) {
      return Promise.reject(t('Failed to validate'));
    }
  };
  const beforeUpload = (file: File) => {
    // Generate a timestamp for the file name
    const timestamp = Date.now();
    const newFileName = `${timestamp}${file.name.substring(file.name.lastIndexOf('.'))}`;

    // Modify the file object to use the new file name
    Object.defineProperty(file, 'name', {
      writable: true,
      value: newFileName,
    });

    // Add the timestamp to the form values
    const formValues = {
      file,
      timestamp,
    };

    // Update the form values before submitting
    form.setFieldsValue(formValues);

    return true;
  };
  const getValueFromEvent = (e: any) => {
    if (e && e.file) {
      return e.file;
    }
    return e && e.target && e.target.value;
  };
  const renderFileLink = (filePath: any) => {
    if (filePath != null && filePath != '') {
      return (
        <a href={Rresource_Path + filePath} target="_blank" rel="noopener noreferrer">
          {t('Download File')}
        </a>
      );
    }
    return null;
  };

  const [subdivisions, setsubdivisions] = useState<SelectProps['options']>([]);
  const [cities, setcities] = useState<CityDto[]>([]);
  const [cityOptions, setCityOptions] = useState<SelectProps['options']>([]);
  const [locationOptions, setLocationOptions] = useState<LocationDto[]>([]);
  const [citylocationOptions, setCityLocationOptions] = useState<SelectProps['options']>([]);

  const locationApi = new LocationApi(configuration);
  const cityApi = new CityApi(configuration);
  const subdivisionApi = new SubdivisionApi(configuration);
  useEffect(() => {

    fetchsubdivisions()
      .then((response) => {
        var mapedOptions: SelectProps['options'] = response?.map(({ name, id }) => (
          {
            label: name,
            value: id
          }
        ));
        setsubdivisions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

    fetchCities()
      .then((response) => {
        setcities(response ?? []);
        // const filteredList = response?.filter((x: CityDto) => formData.subdivision_Id == null || x.subdivision_Id === formData.subdivision_Id);
        // var mapedOptions: SelectProps['options'] = filteredList?.map(({ name, id }) => (
        //   {
        //     label: name,
        //     value: id
        //   }
        // ));
        // setCityOptions(mapedOptions);
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });
    // Fetch the select options from the API
    fetchLocationOptions()
      .then((response) => {
        setLocationOptions(response ?? []);
        // const filteredList = [response?.filter((x: LocationDto) => formData.city_Id == null || x.city_Id === formData.city_Id)];
        // setCityLocationOptions(filteredList?.map(({ name, id }) => (
        //   {
        //     label: name,
        //     value: id
        //   }
        // )));
      })
      .catch((error) => {
        console.error('Failed to fetch select options:', error);
      });

  }, [formData]); // Run this effect only once when the component mounts
  const fetchsubdivisions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return subdivisionApi.subdivisionGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchCities = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return cityApi.cityGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const fetchLocationOptions = () => {
    // Implement your API call here
    // Return a Promise that resolves with the options data
    return locationApi.locationGetList()
      .then((response) => response.data)
      .then((data) => data.items);
  };
  const handleSubdevisionChange = (value: string) => {
    const filteredList = cities?.filter((x: CityDto) => x.subdivision_Id === value);
    console.log('==> handleValuesChange', filteredList);
    setCityOptions(filteredList.map(({ name, id }) => (
      {
        label: name,
        value: id
      }
    )));
  };

  const handleCityChange = (value: string) => {
    const filteredList = locationOptions?.filter((x: LocationDto) => x.city_Id === value);
    console.log('==> handleValuesChange', filteredList);
    setCityLocationOptions(filteredList.map(({ name, id }) => (
      {
        label: name,
        value: id
      }
    )));
  };
  /***end get options */
  const [age, setAge] = useState<number | null>(null);
  function calculateAge(birthDate: Date): number {
    const today = new Date();
    const birthDateDate = new Date(birthDate);
    let age = today.getFullYear() - birthDateDate.getFullYear();
    const month = today.getMonth() - birthDateDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDateDate.getDate())) {
      age--;
    }
    return age;
  }
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Modal title="شروط القبول" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>
          حدود التغطية و شروط القبول لتوفير الخدمات الطبية لصالح مرضى المؤسسة الخيرية الوطنية للرعاية الصحية المنزلية
        </p>
        <p>

          شروط القبول:
          توفر خطاب إحالة منفصل لكل مريض اذا كان محول من جهة طبية اوجمعية او معروض شخصي اذا كان الطلب مقدم من الشخص مباشرة للمؤسسة.
          تقرير طبي لا يتعدى تاريخه مدة شهرين مؤرخ و فيه تشخيص الحالة المرضية , موقع و مختوم من الجهة العلاجية و الطبيب و يذكر فيه بيان بالمعدات و الأجهزة المطلوبة و الوصفة الطبية محدد فيها اسم الدواء و الجرعة.
          · وجود دراسة الحالة الاجتماعية للمرضى المحولين من الجهات الطبية او المراكز الطبية.
          · صورة من هوية سارية المفعول.
          · عقد إيجار (يستثنى سكان الأربطة).
          · خطاب تعريف بالراتب (يستثنى المستفيدون من الضمان الاجتماعي).

        </p>
        <p>

          بالنسبة لحدود التغطية تشمل:
          · الاجهزة الطبية و المعدات (سراير طبية - مراتب هوائية - كراسي متحركة - كراسي حمام – كراسي استحمام – كراسي معاقين بمواصفات خاصة- رافعة كهربائية)
          · أجهزة التنفس الصناعي و أنابيب الأكسجين و أجهزة توليد الأكسجين الكهربائية و مضخات التغذية الكهربائية.
          · أجهزة الربو وأجهزة شفط البلغم و النيبولايزر
          · أجهزة قياس السكر و الضغط
          · المستلزمات الطبية (شاش معقم للتقرحات- أنابيب التغذية – حفائض – مفارش - أشرطة قياس السكر- قساطر بولية – أكياس تجمع بول و براز – أحزمة ضاغطة للحروق)
          · الغذاء الطبي (آنشور – بيدياشور – جلوسيرنا) و اي نوع آخر طبي يذكر في التقرير بإستثناء المكملات الغذائية كالسيريلاك و ماشابه.
          · الأدوية (يستثنى منها الخاص بأمراض السرطان- الفشل الكلوي- النفسية و العقم) تغطى الأدوية فقط لغير السعوديين من المرضى.

        </p>
      </Modal>
      <Modal open={isresultModalOpen}
        closable={false}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={[

        ]}>
        <Result
          status="success"
          title="تم تسجيل الحساب بنجاح"
          subTitle="سوف يتم التخقق من حسابكم وتفعليه باقرب وقت"
          extra={[
            <Button type="primary" key="console" onClick={()=>{
              window.location.href = 'https://wecare360.org/';
              return null;
            }}>
              الى شاشة الدخول
            </Button>,
            <Button key="buy" onClick={()=>{
              window.location.href = 'https://wecare360.org/';
              return null;
            }}>العودة الى الموقع</Button>,
          ]}
        />
      </Modal>

      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col>

          <Spin spinning={loading}>
            <Card>
              <img src="/FullLogo.png" width={350}></img>
              <br />
              <br />
              <br />
              <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={handleSubmit}
                initialValues={{ prefix: '966', code: "patient", serial: 1, partyTypeCode: 'pers', status: 'مفعل', }}
                // style={{ maxWidth: 800 }}
                scrollToFirstError
              >
              <Radio.Group defaultValue={i18n.language} buttonStyle="solid" >
                <Radio.Button value="en" onClick={()=>{  i18n.changeLanguage("en");}}>English</Radio.Button>
                <Radio.Button value="ar" onClick={()=>{  i18n.changeLanguage("ar");}}>العربية</Radio.Button>
              </Radio.Group>
              <br/>
              <br/>
                <Form.Item name="code" label="Code" hidden={true}> <Input disabled /></Form.Item>
                <Form.Item name="serial" label="serial" hidden={true}> <Input disabled /></Form.Item>
                <Form.Item name="partyTypeCode" label="partyTypeCode" hidden={true}> <Input disabled /></Form.Item>
                <Form.Item name="status" label="status" hidden={true}> <Input disabled /></Form.Item>
                <Form.Item
                  name="name"
                  label={t("Name")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="identifierType" label={t("Identifier Type")}
                  rules={[
                    { required: true, message: t('Required field') ?? '' },
                  ]}>
                  <Select options={[
                    { value: 'id_number', label: t('ID Number') },
                    { value: 'residency_id', label: t('Residency ID') },
                    // { value: 'passport', label: t('Passport') },
                  ]} />
                </Form.Item>
                <Form.Item
                  name="identifier"
                  label={t("Identifier")}
                  rules={[
                    { required: true, message: t('Required field') ?? '' },
                    { validator: validateIdentifier },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label={t("Gender")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}
                >
                  <Select options={[
                    { value: 'M', label: t('Male') },
                    { value: 'F', label: t('Female') },
                  ]} />
                </Form.Item>
                <Form.Item
                  name="country_Id"
                  label={t("Nationality")}  >
                  <Select
                    options={countryOptions} />
                </Form.Item>
                <Form.Item
                  name="birthDate"
                  label={t("Birth Date")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}
                  getValueFromEvent={(date) => {
                    const calculatedAge = calculateAge(date.toDate());
                    setAge(calculatedAge);
                    return date;
                  }}
                >
                  <DatePicker format={"YYYY-MM-DD"}
                    placeholder="اختر تاريخ الميلاد"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t("Email")}
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label={t("Phone Number")}
                  rules={[
                    {
                      required: true, message: t('Required field') ?? '',
                      pattern: new RegExp(/^[0-9]+$/)
                    },
                    { validator: validatePhoneNumber },
                  ]} >
                  <Input /*addonBefore={prefixSelector}*/ style={{ width: '100%' }} dir="ltr" />
                </Form.Item>
                {/* <Form.Item
                  name="subdivision_Id"
                  label={t("Subdivision")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}
                >
                  <Select
                    allowClear={true}
                    options={subdivisions} onChange={handleSubdevisionChange} />
                </Form.Item>
                <Form.Item
                  name="city_Id"
                  label={t("City")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}
                >
                  <Select
                    allowClear={true}
                    options={cityOptions} onChange={handleCityChange} />
                </Form.Item>
                <Form.Item
                  name="location_Id"
                  label={t("Location")}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}
                >
                  <Select
                    allowClear={true}
                    options={citylocationOptions} />
                </Form.Item> */}
                <Form.Item
                  name='file1'
                  label={t('Salary Statement')}
                  getValueFromEvent={getValueFromEvent}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}>
                  <Upload
                    action={API_URL_Upload}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    method="post">
                    <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name='file2'
                  label={t('Rental Contract')}
                  getValueFromEvent={getValueFromEvent}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}>
                  <Upload
                    action={API_URL_Upload}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    method="post">
                    <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name='file3'
                  label={t('Patient Identity')}
                  getValueFromEvent={getValueFromEvent}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}>
                  <Upload
                    action={API_URL_Upload}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    method="post">
                    <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name='file4'
                  label={t('Medical Record')}
                  getValueFromEvent={getValueFromEvent}
                  rules={[{ required: true, message: t('Required field') ?? '' }]}>
                  <Upload
                    action={API_URL_Upload}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    method="post">
                    <Button icon={<UploadOutlined />}>{t('Select File')}</Button>
                  </Upload>
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("Password")}
                  rules={[
                    {
                      required: true,
                      message: t('Please confirm your password!') ?? '',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={t("Confirm Password")}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('Please confirm your password!') ?? '',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('The new password that you entered do not match!') ?? ''));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error(t('Should accept agreement') ?? '')),
                    },
                  ]}
                  {...tailFormItemLayout}
                >
                  <Checkbox>
                    {t('I have read the')} <a href="#" onClick={showModal}>{t('agreement')}</a>
                  </Checkbox>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">
                    {t('Register')}
                  </Button>
                  <br />
                  <br />
                  <Button htmlType="button" onClick={Backtosite}>
                    {t('Back to login')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>

          </Spin >
        </Col>
      </Row>
      <OtpConfirm
        phone={phonenumber}
        open={showConfrimOtp}
        onConfirm={function (): void {
          setLoading(true);
          setshowConfrimOtp(false);

          form.validateFields().then(values => {

            values.file1 = normlizeFileVariable(values.file1);
            values.file2 = normlizeFileVariable(values.file2);
            values.file3 = normlizeFileVariable(values.file3);
            values.file4 = normlizeFileVariable(values.file4);

            values.subdivision_Id = "3a0cd8a1-6628-5406-44e5-19ff159a3a3c";
            values.city_Id = "3a0d0551-e7ac-e7d5-c905-aad5139599ee";
            values.location_Id = "3a0c5d37-5dea-2b25-8a90-314e9c908c9b";

            if (values.birthDate != null)
              values.birthDate = dayjs(values?.birthDate).format("YYYY-MM-DD")

            partyApi.partyRegisterAccount(values)
              .then(response => {
                setLoading(false);
                setisresultModalOpen(true);
                // Handle the API response
                if (response.data.error == true) {
                  message.error(response.data.message);
                  return false; // Replace with your own logic
                } else {
                  message.success(t('Submit Successfully'));

                }
              })
              .catch(error => {
                setLoading(false);
                // Handle the API error
                console.error(error);
                message.error(error);
              });

          });
        }} onCancel={function (): void {
          setshowConfrimOtp(false);
        }} />
    </>
  );
};